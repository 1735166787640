<template>
    <div class="contents">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("top-select-ai-vod-data-list") }}</h1>
        <div class="vodCont">
            <div class="box one filter"
                style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center;">
                <div class="flex">
                    <p class="bold">{{ $t("search-keyword-title") }}</p>
                    <select v-model="keywordType">
                        <option value="all">{{ $t("search-keyword-all") }}</option>
                        <option value="title">{{ $t("vod-data-title") }}</option>
                        <option value="eventViewId">{{ $t("event-data-site-num") }}</option>
                        <option value="eventName">{{ $t("event-data-site-name") }}</option>
                        <option value="registerUserName">{{ $t("live-data-channel-operator") }}</option>
                    </select>
                    <input type="text" v-model="keyword" @keydown.enter="search" />
                </div>
                <div class="flex">
                    <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
                    <Datepicker v-model="startYmd" :language="ko" :format="customFormatter" placeholder="YYYY-MM-DD">
                    </Datepicker>
                    <span>~</span>
                    <Datepicker v-model="endYmd" :language="ko" :format="customFormatter" placeholder="YYYY-MM-DD">
                    </Datepicker>
                </div>

                <div class="buttonWrap" style="display: flex; margin-top: 0px;">
                    <button class="point medium" @click="search" style="width: 120px; margin-left: 10px;">
                        {{ $t("btn-searching") }}
                    </button>
                    <button class="medium margin6" @click="resetPage" style="width: 120px;">
                        {{ $t("btn-reset") }}
                    </button>
                </div>
            </div>
            <div class="box one">
                <div class="flexB" style="flex-flow: row; margin-bottom: 8px;">
                    <h2 class="bold" style="margin-bottom: 0;line-height: 28px;">
                        {{ $t("data-total") }} <span class="blue">{{ total }}</span>{{ $t("data-case-ea") }}
                    </h2>

                    <div class="buttonWrap" style="margin-top: 0px;">

                        <button class="deleteBtn" @click="deleteAiVod">
                            {{ $t("btn-delete") }}
                        </button>
                    </div>
                </div>
                <table style="margin-bottom: 5px;">
                    <tr>
                        <th>{{ $t("user-data-dept-select") }}</th>
                        <th>{{ $t("vod-data-thum") }}</th><!-- 썸네일 -->
                        <th>{{ $t("vod-data-title") }}</th><!--제목-->
                        <th>{{ $t("event-data-site-num") }}</th><!--현장번호-->
                        <th>{{ $t("event-data-site-name") }}</th><!--현장명-->
                        <th>{{ $t("data-operation-device-type") }}</th><!--기기구분-->
                        <th>{{ $t("live-data-channel-operator") }}</th><!--촬영자-->
                        <th>{{ $t("live-data-channel-operator-dept") }}</th><!--촬영자 소속-->
                        <th>{{ $t("vod-data-registered-date") }}</th><!--등록일-->
                        <!-- <th>기기 SD 파일</th> -->
                    </tr>
                    <colgroup>
                        <col style="width: 5%;" />
                        <col style="width: 10%;" />
                        <col style="width: 15%;" />
                        <col style="width: 10%;" />
                        <col style="width: 15%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                    </colgroup>
                    <tr v-if="total == 0">
                        <td colspan="9" style="text-align: center;">
                            {{ $t("search-not-found") }}
                        </td>
                    </tr>
                    <tr v-for="(data, i) in vodList" :key="i">
                        <td>
                            <label><input type="checkbox" name="delSelect" v-model="selectIds"
                                    :value="data.contentsId" /></label>
                        </td>
                        <td>
                            <router-link :to="{
                                name: 'AiVodDetail',
                                params: { contentsId: data.contentsId, eventId: eventId }
                            }">
                                <div class="thumBox" style="position: relative;">
                                    <img style="width: 80px;" :src="baseUrl + data.thumbnailUri" />
                                    <div class="duration">
                                        <span class="duration">{{ changeDuration(data.duration) }}</span>
                                    </div>
                                </div>
                            </router-link>
                        </td>
                        <td>
                            {{ data.title }}
                        </td>
                        <td>{{ data.eventViewId != null ? data.eventViewId : data.eventId }}</td>
                        <td>
                            {{ data.eventTitle }}
                        </td>
                        <td>
                            {{ $t(getDeviceTypeName(data.deviceType)) }}
                        </td>
                        <td>{{ data.registerUserName }}</td>
                        <td>{{ data.registerDepartmentName }}</td>
                        <td>
                            {{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
                        </td>
                    </tr>
                </table>
                <div class="pagination">

                    <el-pagination small layout="prev, pager, next" :total="total" :page-size="size"
                        :current-page="currentPage" @prev-click="handleCurrentChange(-1)"
                        @next-click="handleCurrentChange(1)" @current-change="moveToSelectPage">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { baseUrl } from "@/config/env";
import { fetchVodList, fetchVodThumbnail, deleteVodList} from "@/api/contents";
import { getDeviceTypeName } from "@/api/channel";
import { mapState } from "vuex/dist/vuex.common.js";

export default {
    components: { Datepicker },
    name: "ContentsList",
    data() {
        return {
            baseUrl: baseUrl,
            ko: ko,
            moment: moment,
            eventId: null,

            vodList: [],
            selectIds: [],
            viewDeletedContents: false,

            total: 0,
            size: 10,
            startYmd: null,
            endYmd: null,
            currentPage: null,
            keywordType: null,
            keyword: null,
            
        };
    },
    created() {
        this.eventId = this.$route.params.eventId
        this.initData()
    },
    mounted() {
        this.getAiVodList()
        
    },
    computed: {
        ...mapState({
            userType: state => state.store.userType,
            accountId: state => state.store.accountId,
        }),
    },
    methods: {
        initData() {
            this.currentPage = Number(this.$route.query.page) || 1
            this.keywordType = this.$route.query.keywordType || 'all'
            this.keyword = this.$route.query.keyword || ''
            if (this.$route.query.startYmd !== undefined && this.$route.query.startYmd !== null) {
                this.startYmd = this.$route.query.startYmd
            } 
            if (this.$route.query.endYmd !== undefined && this.$route.query.endYmd !== null) {
                this.endYmd = this.$route.query.endYmd
            } 

        },

        deleteAiVod() {
            if (!confirm(this.$t("alert-message-delete-contents-message"))) {
                return;
            }
            let params = {
                ids: this.selectIds.join(",")
            }
            deleteVodList(params).then((res) => {
                if (res.data.result == 0) {
                    this.vodList = this.vodList.filter(vod => !this.selectIds.includes(vod.contentsId));
                    this.total -= this.selectIds.length
                    this.selectIds = []
                }
            });
        },
        


        /**
         * 검색
         * query로 키워드, 날짜등을 전달하여 페이지이동함
         */
        search() {
            if (this.startYmd === null || this.startYmd === undefined || this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
                return alert(this.$t("alert-message-check-regdate"));
            }
            this.$router.push({
                name: `AiVodList`,
                query: {
                    page: 1,
                    startYmd: moment(this.startYmd).format('YYYY-MM-DD'),
                    endYmd: moment(this.endYmd).format('YYYY-MM-DD'),
                    keyword: this.keyword,
                    keywordType: this.keywordType,
                },
            })
        },
        getDeviceTypeName(data) {
            return getDeviceTypeName(data);
        },


        /**
         * prev, next 버튼으로 페이지 이동
         * @param number prev: -1, next: +1
         */
        handleCurrentChange(number) {
            this.$router.push({
                name: `AiVodList`,
                query: {
                    page: this.currentPage + number,
                    startYmd: this.startYmd,
                    endYmd: this.endYmd,
                    keyword: this.keyword,
                    keywordType: this.keywordType,
                }
            })
        },

        /**
         * 선택한 페이지로 이동
         * @param pageNumber 선택한 페이지번호
         */
        moveToSelectPage(pageNumber) {
            this.$router.push({
                name: `AiVodList`,
                query: {
                    page: pageNumber,
                    startYmd: this.startYmd,
                    endYmd: this.endYmd,
                    keyword: this.keyword,
                    keywordType: this.keywordType,
                },
            })
        },

        resetPage() {
            this.$router.push({
                name: `AiVodList`,
                query: {
                    page: 1,
                },
            })
        }, 

        

        customFormatter(date) {
            return moment(date).format("YYYY년 MM월 DD일");
        },

        changeDuration(seconds) {
            if (seconds == null) {
                return "00:00";
            }
            var min = parseInt((seconds % 3600) / 60) < 10 ? '0' + parseInt((seconds % 3600) / 60) : parseInt((seconds % 3600) / 60);
            var sec = seconds % 60 < 10 ? '0' + seconds % 60 : seconds % 60;
            return min + ":" + sec;
        },

        getAiVodList() {
            let params = {
                pageNumber: this.currentPage - 1,
                pageSize: this.size,
                keywordType: this.keywordType,
                keyword: this.keyword,
                findFileClass : "AI",
                eventId: this.eventId
            };
            params["registeredDateRange.from"] = this.startYmd;
            params["registeredDateRange.to"] = this.endYmd;

            fetchVodList(params).then((res) => {
                this.total = res.data.data.total;
                this.vodList = res.data.data.content;
                for (let i in this.vodList) {
                    this.vodList[i].thumbnail = this.getThumbnail(this.vodList[i].contentsId);
                }
            });
        },
        async getThumbnail(contentId) {
            const res = await fetchVodThumbnail(contentId)
            const thumb = res.data
            return thumb;
        },
    },
};
</script>

<style>
.title {display: none;}
.deleteBtn{border: 1px solid #ddd;border-radius: 5px;color: #969696;padding: 0 30px;margin: 0 5px;height: 35px;font-weight: 400;}
.deleteBtn:hover{border: 1px solid #ff5656;color: #ff5656;}
div.duration {
    position: absolute;
    width: 36px !important;
    height: 18px !important;
    right: 0;
    bottom: 0;
    padding: 1px 4px 2px 5px;
    background-color: rgba(30, 30, 30, 0.5);
}

span.duration {
    font-family: Noto Sans KR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
</style>
